@import url('https://fonts.cdnfonts.com/css/lato');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Lato', sans-serif;
    @apply bg-gray-300
}

.mapboxgl-ctrl-logo {
    display: none !important;
}
